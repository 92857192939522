.trip-detail-page {
  // ... existing styles

  // Style for the new section button
  button {
    margin-top: 20px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
  }
}
