.custom-toggle {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Space between text and toggle */
  width: 100%; /* Stretch horizontally */
}

.custom-toggle-input {
  /* Hidden but accessible */
  opacity: 0;
  position: absolute; /* Make sure it doesn't take space */
  z-index: -1; /* Put it behind the label visually */
}

.custom-toggle-label {
  /* Custom toggle appearance */
  cursor: pointer;
  width: 50px; /* Width of the toggle */
  height: 25px; /* Height of the toggle */
  background-color: #ccc;
  border-radius: 100px;
  position: relative; /* For the toggle handle positioning */
}

.custom-toggle-label:after {
  /* Handle for the toggle */
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 21px;
  transition: 0.4s;
}

.custom-toggle-input:checked + .custom-toggle-label {
  background-color: #2196F3;
}

.custom-toggle-input:checked + .custom-toggle-label:after {
  /* Move the handle when checked */
  transform: translateX(25px);
}


.transaction-container {
  position: relative; /* This makes the positioning of children absolute relative to this container */
  /* Your existing styles for the container */
}

.btn-fab {
  position: absolute; /* Absolute position */
  bottom: 20px; /* Distance from bottom of the container */
  right: 20px; /* Distance from right of the container */
  width: 56px; /* Width and height for a circular shape */
  height: 56px;
  border-radius: 50%; /* Rounded borders */
  background-color: #ffa500; /* Orange background */
  color: #ffffff; /* White text color */
  text-align: center; /* Center the content */
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26); /* Shadow for the button */
  font-size: 24px; /* Larger font size for the plus sign */
  line-height: 40px; /* Center the plus sign vertically */
  z-index: 10; /* Ensure it's above other items within the container */
}

.btn-fab:hover {
  background-color: #e69500; /* Darker orange on hover */
  cursor: pointer; /* Indicate clickable item */
}


.btn-custom {
  background-color: #4A5FCC; /* Unified blue background color */
  color: #ffffff; /* White text color for better readability */
  border: none; /* Maintain no borders for a cleaner look */
  padding: 8px 16px; /* Standardized padding for better touch interaction */
  font-size: 16px; /* Increased font size for readability */
  border-radius: 5px; /* Rounded corners for a softer, modern look */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for all properties */
  margin-right: 3px; /* Sufficient spacing between buttons */
}

.btn-custom:hover, .btn-custom.active {
  background-color: #364DAB; /* Darker blue for hover and active states */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover/active */
}



.bg-light-green {
  background-color: #a9e5bb; /* Light green background color */
  padding: 30px 10px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
}

.bg-light-green span {
  color: #6c757d; /* Text color for "Days Remaining" */
}

.bg-pastel-purple {
  background-color: #d1a6ff; /* Pastel purple background color */
}

.text-pastel-red {
  color: #ff7f7f; /* Pastel red text color */
}

.text-pastel-green {
  color: #7fff7f; /* Pastel green text color */
}

.text-pastel-orange {
  color: #ffb77f; /* Pastel orange text color */
}


.bg-light-green {
  background-color: #a9e5bb; /* Light green background color */
  padding: 30px 10px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
}

.bg-light-red {
  background-color: #ffb3b3; /* Light red background color */
  padding: 30px 10px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
}

.bg-light-green span,
.bg-light-red span {
  color: #6c757d; /* Text color for "Days Remaining" */
}

.custom-card-title {
  font-size: 1.25rem; /* Specific font size for card titles */
  color: #333; /* Dark gray color for better readability */
  margin-bottom: 1rem; /* Bottom margin for spacing */
}

.total-due {
  font-weight: bold; /* Bold font for emphasizing total due amount */
  color: #333; /* Consistent color with the title for uniformity */
}


.date-info {
  color: #6c757d; /* Consistent color throughout the application for similar components */
}

.day-number {
  font-size: 1.5rem; /* Larger font size for day number to highlight it */
}

.day-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px; /* Provides spacing between the day number and details */
}

.day-abbr {
  padding: 0.25rem 0.5rem; /* Adjust padding for better visual balance */
  margin-bottom: 5px; /* Space between the abbreviation and remaining days text */
}

.remaining-days {
  font-size: 0.875rem; /* Smaller font size for less important information */
  color: #555; /* Slightly darker color for better readability */
}

.pay-button {
  font-size: 0.875rem;
  padding: 0.5rem 1rem; /* Adjust padding for button size */
  margin-top: 5px; /* Extra space above the button */
}

.circular-progress {
  width: 100%;
  height: 100%;
}

.highlight-green {
  background-color: #d4edda; /* Light green for paid */
}

.highlight-red {
  background-color: #f8d7da; /* Light red for almost due */
}

.highlight-yellow {
  background-color: #fff3cd; /* Light yellow for regular highlighted */
}
